.custom-datatable-container input[data-filter-id="manufacturer"] {
  width: 100px;
}

.custom-datatable-container input[data-filter-id="model"] {
  width: 200px;
}

.custom-datatable-container input[data-filter-id="spine"] {
  width: 30px;
}

.custom-datatable-container input[data-filter-id="gpi"] {
  width: 30px;
}

.custom-datatable-container input[data-filter-id="od"] {
  width: 25px;
}

.custom-datatable-container input[data-filter-id="id"] {
  width: 25px;
}

.custom-datatable-container input[data-filter-id="stock length (in)"] {
  width: 75px;
}

.custom-datatable-container input[data-filter-id="insert weight (grain)"] {
  width: 75px;
}
