.data-editor-table {
  width: 100%;
  border-collapse: collapse;
}

.data-editor-th,
.data-editor-td {
  border: 1px solid #ddd;
  padding: 8px;
}

.data-editor-input {
  box-sizing: border-box;
  width: 100%;
}

.property-column {
  width: 150px;
}
