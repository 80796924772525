.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
}

.wave {
  width: 100%;
  max-height: 200px;
  margin-top: -100px;
}
