/* CSS for TagDisplay component */

table {
  border-collapse: collapse;
  width: 100%;
}

td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

tr:last-child td {
  border-bottom: none;
}

tr:hover {
  background-color: #f5f5f5;
}

thead {
  border-bottom: 1px solid #ddd;
}

.title {
  font-weight: bold;
  text-align: left;
}
