.headerContainer {
  text-align: center;
}

.linkContainer {
  text-align: center;
  margin-bottom: 14px;
}

.link {
  display: inline-block;
  margin: 6px;
  font-weight: bold;
  text-decoration: none;
  transition: 0.1s ease-in;
}

/*
.link:hover {
  color: #007ecc;
}
*/

/*
.active:hover {
  color: #00b9a6;
}
*/
h1 {
  display: block;
  text-align: center;
  color: #ff449f;
  font-family: "Roboto";
  font-weight: bold;
}

.header {
  padding: 5px;
}
