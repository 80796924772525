.leftColumn {
  flex-basis: 30%;
  flex-grow: 0;
  flex-shrink: 0;
}

.rightColumn {
  flex-basis: 70%;
  flex-grow: 0;
  flex-shrink: 0;
}

.collapsed .rightColumn {
  display: none;
}

.bowPageHeader {
  background-color: lightgray;
  padding: 10px;
  cursor: pointer;
}

.collapseIcon {
  float: right;
  margin-top: 2px;
  font-weight: bold;
}

.collapsed .collapseIcon {
  transform: rotate(90deg);
}

.react-toggle {
  margin-right: 8px;
  vertical-align: middle;
}
