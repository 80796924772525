.multiSearchBarContainer {
  display: flex;
  flex-direction: column;
}

.multiSearchBarContainer select {
  border: 1px solid #aaa;
  padding: 10px;
}

.multiSearchBarContainer select:active {
  border: 1px solid #3a86ff;
}
