.custom-collar-datatable-container input[data-filter-id="manufacturer"] {
  width: 100px;
}

.custom-collar-datatable-container input[data-filter-id="model"] {
  width: 200px;
}

.custom-collar-datatable-container input[data-filter-id="outsert id"] {
  width: 30px;
}

.custom-collar-datatable-container input[data-filter-id="outsert od"] {
  width: 30px;
}

.custom-collar-datatable-container input[data-filter-id="point size"] {
  width: 25px;
}

.custom-collar-datatable-container input[data-filter-id="shaft size"] {
  width: 30px;
}

.custom-collar-datatable-container input[data-filter-id="weight (grain)"] {
  width: 75px;
}
